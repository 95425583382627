import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TicTacToe } from './components/games/tic-tac-toe/TicTacToe';
import { Blog } from './components/blog/Blog'
function App() { 
  return (
  
  <Router>
    <Routes>
      <Route path="/blog" element={<Blog/>} />
      <Route path="/" element={<TicTacToe/>} />
      <Route path="/tic-tac-toe" element={<TicTacToe/>} />
    </Routes>
  </Router> 
  );
}

export default App