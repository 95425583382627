import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import GitHubIcon from '@mui/icons-material/GitHub';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import post1 from './blog-post.1.md';
// import post2 from './blog-post.2.md';
import ResponsiveAppBar from '../main/ResponsiveAppBar';
import { TitleProvider } from '../main/TitleProvider';

// const sections = [
//   { title: 'Technology', url: '#' },
//   { title: 'Design', url: '#' },
//   { title: 'Culture', url: '#' },
//   { title: 'Business', url: '#' },
//   { title: 'Politics', url: '#' },
//   { title: 'Opinion', url: '#' },
//   { title: 'Science', url: '#' },
//   { title: 'Health', url: '#' },
//   { title: 'Style', url: '#' },
//   { title: 'Travel', url: '#' },
// ];

const mainFeaturedPost = {
  title: 'Tic-Tac-Toe its fine right?',
  description:
    "Playing a game of tic-tac-toe is a delightful way to engage your mind and enjoy some competition. The simplicity of the game makes it a timeless classic that brings joy and excitement.",
  image: 'https://source.unsplash.com/random?wallpapers',
  imageText: 'random image from unsplash.com',
  linkText: 'Continue reading…',
  href: "#the-joy-of-tic-tac-toe-a-simple-game-with-big-benefits"
};

const featuredPosts = [
  // {
  //   title: 'Featured post',
  //   date: 'Nov 12',
  //   description:
  //     'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random?wallpapers',
  //   imageLabel: 'Image Text',
  // },
  // {
  //   title: 'Post title',
  //   date: 'Nov 11',
  //   description:
  //     'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random?wallpapers',
  //   imageLabel: 'Image Text',
  // },
];

const posts = [post1];

const sidebar = {
  title: 'About',
  description:
    'Welcome to our blog, your ultimate source for all things video games. We delve into the captivating worlds of console and web-based gaming, providing insightful reviews, latest news, and in-depth analysis. Join us as we explore the dynamic universe of video games, where imagination meets.',
  // archives: [
  //   { title: 'March 2020', url: '#' },
  //   { title: 'February 2020', url: '#' },
  //   { title: 'January 2020', url: '#' },
  //   { title: 'November 1999', url: '#' },
  //   { title: 'October 1999', url: '#' },
  //   { title: 'September 1999', url: '#' },
  //   { title: 'August 1999', url: '#' },
  //   { title: 'July 1999', url: '#' },
  //   { title: 'June 1999', url: '#' },
  //   { title: 'May 1999', url: '#' },
  //   { title: 'April 1999', url: '#' },
  // ],
  // social: [
  //   { name: 'GitHub', icon: GitHubIcon },
  //   { name: 'Twitter', icon: TwitterIcon },
  //   { name: 'Facebook', icon: FacebookIcon },
  // ],
};

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export const Blog = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <TitleProvider title="Eletric Mike: Video Game Blog" />
      <CssBaseline />
      <Container maxWidth="lg">
        <ResponsiveAppBar />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Main title="From the firehose" posts={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
          </Grid>
        </main>
      </Container>
      <Footer
        title="ELECTRIC-MIKE"
        description="Ba-DaDa-Ba Bang-DaDa-Bang Video-Games!"
      />
    </ThemeProvider>
  );
}
