import React, { useState, useEffect } from "react";

import { Board } from "./Board";
import { ResetButton } from "./ResetButton";
import { ScoreBoard } from "./ScoreBoard";
import { Toggle } from "./Toggle"
import './TicTacToe.css';
import { ResponsiveAppBar } from "../../main/ResponsiveAppBar";
import { TitleProvider } from "../../main/TitleProvider";

export const TicTacToe = () => {

  const WIN_CONDITIONS = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6]
  ]
  const [toggleChecked, setToggleChecked] = useState(true);
  const [xPlaying, setXPlaying] = useState(true);
  const [board, setBoard] = useState(Array(9).fill(null))
  const [scores, setScores] = useState({ xScore: 0, oScore: 0 })
  const [gameOver, setGameOver] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  const generateRandomMove = (board) => {
    let indices = [];

    // Find all empty spots on the board
    for (let i = 0; i < 9; i++) {
      if (!board[i]) indices.push(i);
    }
  
    // Select a random index from the available spots
    return indices[Math.floor(Math.random() * indices.length)];
  }

  const handleToggleChange = (checked) => {
    setToggleChecked(checked);
  }

  useEffect(() => {
    // Only execute when it's the AI's turn
    // You may want to adjust the logic when allowing the player to choose who starts first
    if (toggleChecked && !xPlaying && !gameOver) {
      setTimeout(() => {
        handleBoxClick(generateRandomMove(board));
      }, 200); // 2 millisecond delay
    }
  }, [xPlaying]);

  const handleBoxClick = (boxIdx) => {
    setGameStarted(true);

    // Step 1: Update the board
    const updatedBoard = board.map((value, idx) => {
      if (idx === boxIdx) {
        return xPlaying ? "X" : "O";
      } else {
        return value;
      }
    })
    setBoard(updatedBoard);
    // Step 2: Check if either player has won the game
    const winner = checkWinner(updatedBoard);

    if (winner) {
      if (winner === "O") {
        let { oScore } = scores;
        oScore += 1;
        setScores({ ...scores, oScore })
      } else {
        let { xScore } = scores;
        xScore += 1;
        setScores({ ...scores, xScore })
      }
    }

    // Step 3: Change active player
    setXPlaying(!xPlaying);
  }

  const checkWinner = (board) => {
    for (let i = 0; i < WIN_CONDITIONS.length; i++) {
      const [x, y, z] = WIN_CONDITIONS[i];

      // Iterate through win conditions and check if either player satisfies them
      if (board[x] && board[x] === board[y] && board[y] === board[z]) {
        setGameOver(true);
        return board[x];
      }
    }
  }

  const resetBoard = () => {
    setGameOver(false);
    setBoard(Array(9).fill(null));
    setXPlaying(true)
    setGameStarted(false)
  }


  return (
    
    <div className="TicTacToe">
      <TitleProvider title="one or two player tic tac toe" />
      <ResponsiveAppBar />
      <ScoreBoard scores={scores} xPlaying={xPlaying} />
      <Board board={board} onClick={gameOver ? resetBoard : handleBoxClick} />
      <ResetButton resetBoard={resetBoard} />
      <Toggle disabled={gameStarted} checked={toggleChecked} onChange={handleToggleChange}/>
    </div>
  );
}
