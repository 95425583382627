import React from 'react';
import ReactSwitch from 'react-switch';
import './Toggle.css';


export const Toggle = ({ disabled, checked, onChange}) => {

  return (
    <div className="toggle">
        <div className="switch-container">
            <span>2 Player</span>  
            <ReactSwitch
                disabled={disabled}
                checked={checked}
                onChange={onChange}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#FF4625"
                className="react-switch"
            />
            <span>1 Player</span>
        </div>
    </div>
  );
}
